export default {
	appInfo: {
		name: "E-Meterai OP",
		version: '1.0.0',
		appname: null,
		subname: "E-Meterai-OP",
	},
	timestamp: '2021-01-13T04:38:25.948Z',
	theme: 'ad2700c315f98a65120c4a8503860c1b',
	apiGateway: {
		host: '',
		clientId: '8qKOfRjjsYIyzZHmxY8Va4PxpZw5XTHGMnqczkEz',
		state: 'mpk-starter-spa-product',
		baseUrl: '/api/v1'
	},
	sso: {
		host: 'https://api.staging.pajakku.com',
		sso: 'http://sso.staging.pajakku.com'
	},
	appConsole: {
		website: {
			label: 'Website Pajakku',
			url: 'https://portal.staging.pajakku.com'
		},
		userAccount: {
			label: 'User Account Console',
			url: 'https://user.staging.pajakku.com'
		},
		developer: {
			label: 'Developer Console',
			url: 'https://developer.staging.pajakku.com'
		}
	},
	widgetInterface: {
		kbs: {
			url: 'https://kbs.pajakku.com/static/libs/kbs-widget.min.js'
		},
		qm: {
			url: 'https://quotamgmt.bdg.pajakku.com/static/libs/qm-widget-pajakku.min.js',
			urlAccessClientId: 'm5zxKHtLFxQ5P179q8fiqehdD8JqceGjw78vX3Mh',
			applicationClientId: '3d442eb24ed54489a6b92d9fdc599fd2'
		}
	},
	widgetGateway: {
		host: 'https://widget.staging.pajakku.com',
		ws: 'wss://widget.staging.pajakku.com',
		baseUrl: '',
		applicationType: 'product'
	},
	kbs: [
		{
			label: 'kbs.manualGuide',
			type: 'documentation',
			code: 'DOC-STARTER-SPA',
			translate: true
		}
	],
	applicationType: 'onpremise',
	locale: {
		code: 'id',
		options: [
			{
				label: 'Bahasa',
				value: 'id'
			},
			{
				label: 'English',
				value: 'en'
			}
		]
	},
	portal: {
		channelName: '',
		forumUrl: 'https://pajakku.com/forum-categories'
	}
}
